<template>
    <b-card>
        <Form ref="createPost" :post="post" :submit="submit"/>
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "EditPost",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.findPost(vm.id)
        })
    },

    components: {
        Form
    },

    data() {
        return {
            id: this.$route.params.id
        }
    },

    computed: {
        ...mapGetters('post', [
            'post'
        ]),
    },

    methods: {
        ...mapActions({
            editPost: 'post/edit',
            findPost: 'post/find',
        }),
        submit() {
            const refPost = this.$refs.createPost
            refPost.errMessage = null

            const data = JSON.parse(JSON.stringify(refPost.post))
            data.meta_keywords = data.meta_keywords.toString()
            data.compatible = data.compatible.toString()
            data.genres = data.genres.map(genre => parseInt(genre.value))
            data.actors = data.actors.map(actor => parseInt(actor.value))
            data.categories = data.categories.map(category => parseInt(category.value))
            // if (data.status === PostStatus.DRAFT) data.published_at = null
            this.editPost(data).then(() => {
                this.$router.push({ name: 'posts' })
                this.$swal({
                    icon: 'success',
                    title: this.$t(`Edited!`),
                    text: this.$t('Your file has been edited.'),
                    confirmButtonText: this.$t('Ok!'),
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            }).catch(response => {
                if (response.errors) {
                    refPost.$refs.form.setErrors(response.errors)
                }
                refPost.errMessage = response.message
            })

        }
    }
}
</script>
